import React from 'react'
import Layout from '../components/layout'
import HeroRow from '../components/hero-row'
import get from 'lodash/get'
import Img from 'gatsby-image'
import './floorplans.css'
import { Link } from 'gatsby'

export default class Floorplans extends React.Component {

  render() {
    const plans = get(this, 'props.data.allContentfulPlan.edges')
    return (
      <Layout>
        <div className="container-fluid">
          <HeroRow text="Floorplans" subtext="Amazing floorplans for every lifestlye." />
            {plans.map(({ node }, index) => {
              return (
                <div className="row row-floorplan" key={index}>
                  <div className="col-md-7">
                    <h1>{node.name}</h1>
                    <div>
                      <h5>{node.sqft} Sq/Ft</h5>
                      <h5>{node.bedbath}</h5>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: node.description.childMarkdownRemark.html,
                      }}
                    />
                    <div>
                      <Link to="/contact"><button className="btn btn-primary">Contact for pricing</button></Link>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <Img 
                      alt={node.twodimensional.name}
                      fluid={node.twodimensional.fluid}
                    />
                  </div>
                </div>
              )
            })}
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query FloorplansQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPlan {
      edges {
        node {
          name,
          sqft,
          bedbath,
          twodimensional {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`